<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="card rounded-3">
            <div class="card-header d-flex justify-content-between align-items-center ps-4 pe-4 pt-2 pb-2">
                <h6 class="fs-16 mb-0">{{ $t('coupon.editInfo') }}</h6>
            </div>
            <div class="card-body shadow-lg">
                <div class="container">
                    <form ref="couponForm" @submit.prevent="update">
                        <div class="row gap-5">
                            <div class="col-lg-5">
                                <div class="d-flex flex-column gap-3">
                                    <div class="d-flex flex-column">
                                        <label>{{ $t('coupon.name') }} <span class="text-danger">*</span></label>
                                        <input :maxlength="255" :placeholder="$t('coupon.namePlaceholder')"
                                            v-model="state.coupon.name" type="text" class="form-control" />
                                        <div v-if="v$.name.$error" class="invalid-feedback d-flex flex-column">
                                            <span v-if="v$.name.required.$invalid">{{
                                                v$.name.required.$message
                                            }}</span>
                                            <span v-if="v$.name.maxLength.$invalid && !v$.name.required.$invalid">{{
                                                v$.name.maxLength.$message
                                            }}</span>
                                        </div>
                                    </div>

                                    <div class="d-flex flex-column">
                                        <label>{{ $t('coupon.code') }}</label>
                                        <input :value="state.coupon.code" readonly disabled placeholder="A0230720"
                                            type="text" class="form-control" />
                                    </div>


                                    <div class="d-flex flex-column">
                                        <label>{{ $t('coupon.expires') }} <span class="text-danger">*</span></label>
                                        <div class="d-flex gap-2">
                                            <div class="d-flex flex-column">
                                                <FlatPickr v-model="state.coupon.startTime"
                                                    :placeholder="$t('coupon.startPlaceholder')"
                                                    :config="startTimePickerConfig" class="form-control" />
                                                <div v-if="v$.startTime.$error" class="invalid-feedback d-flex flex-column">
                                                    <span v-if="v$.startTime.required.$invalid">{{
                                                        v$.startTime.required.$message
                                                    }}</span>
                                                </div>
                                            </div>


                                            <div class="pt-2">~</div>

                                            <div class="d-flex flex-column">
                                                <FlatPickr v-model="state.coupon.endTime"
                                                    :placeholder="$t('coupon.endPlaceholder')" :config="endTimePickerConfig"
                                                    class="form-control" />
                                                <div v-if="v$.endTime.$error" class="invalid-feedback d-flex flex-column">
                                                    <span v-if="v$.endTime.required.$invalid">{{
                                                        v$.endTime.required.$message
                                                    }}</span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="d-flex flex-column">
                                        <label>{{ $t('coupon.remarks') }}</label>
                                        <textarea rows="10" :maxlength="500" v-model="state.coupon.memo" type="text"
                                            class="form-control" />
                                        <div v-if="v$.memo.$error" class="invalid-feedback d-flex flex-column">
                                            <span v-if="v$.memo.maxLength.$invalid">{{
                                                v$.memo.maxLength.$message
                                            }}</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-lg-5">
                                <div class="d-flex flex-column gap-4">
                                    <div class="d-flex flex-column">
                                        <label>{{ $t('coupon.couponvalid') }} <span class="text-danger">*</span></label>
                                        <div class="d-flex gap-5">
                                            <div class="form-check form-check-inline form-check-right">
                                                <input v-model="state.coupon.isActive" class="form-check-input" type="radio"
                                                    name="valid" id="valid" value="true">
                                                <label class="form-check-label" for="valid">{{ $t('coupon.valid') }}</label>
                                            </div>
                                            <div class="form-check form-check-inline form-check-right">
                                                <input v-model="state.coupon.isActive" class="form-check-input" type="radio"
                                                    name="invalid" id="invalid" value="false">
                                                <label class="form-check-label" for="invalid">{{ $t('coupon.invalid')
                                                }}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="d-flex flex-column">
                                        <label>{{ $t('coupon.maxUsage') }}</label>
                                        <div class="d-flex gap-4">
                                            <div>
                                                <input :placeholder="$t('coupon.usagePlaceholder')" v-maska="timesMarka"
                                                    v-model="state.coupon.quantity" type="text"
                                                    class="form-control usageInput" />


                                                <div v-if="v$.quantity.$error" class="invalid-feedback d-flex flex-column">
                                                    <span v-if="v$.quantity.integer.$invalid">{{
                                                        v$.quantity.integer.$message
                                                    }}</span>
                                                    <span
                                                        v-if="v$.quantity.minValue.$invalid && !v$.quantity.integer.$invalid">{{
                                                            v$.quantity.minValue.$message
                                                        }}</span>
                                                    <span
                                                        v-if="v$.quantity.maxValue.$invalid && !v$.quantity.minValue.$invalid && !v$.quantity.integer.$invalid">{{
                                                            v$.quantity.maxValue.$message
                                                        }}</span>
                                                </div>
                                            </div>
                                            <span class="mt-2">{{ $t('coupon.times') }}</span>
                                        </div>

                                    </div>

                                    <div class="d-flex flex-column">
                                        <label>{{ $t('coupon.limitOnday') }} <span class="text-danger">*</span></label>
                                        <div class="d-flex gap-5">
                                            <div class="form-check form-check-inline form-check-right">
                                                <input v-model="state.coupon.maxNumberInDay" class="form-check-input"
                                                    type="radio" name="specify" id="specify" value="1">
                                                <label class="form-check-label" for="specify">{{ $t('coupon.specify')
                                                }}</label>
                                            </div>
                                            <div class="form-check form-check-inline form-check-right">
                                                <input v-model="state.coupon.maxNumberInDay" class="form-check-input"
                                                    type="radio" name="notSpecify" id="notSpecify" value="">
                                                <label class="form-check-label" for="notSpecify">{{ $t('coupon.notSpecify')
                                                }}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="d-flex flex-column">
                                        <label>{{ $t('coupon.couponImage') }}</label>
                                        <DropZone :type="state.type" :propNameFile="dropzoneFile.name" @clearFile="clearFile" :showLabel="true"
                                            ref="dropZone" :allowedExtensions="allowedExtensions"
                                            @selectedFile="selectedFile" accept="image/*" />
                                        <div v-if="dropzoneFile">
                                            <img class="cp-image" alt="cp-image" :src="state.imageSrc" />
                                        </div>
                                        <div v-if="!dropzoneFile" class="pt-2 text-danger white-space-preline">
                                            {{ $t('coupon.suggest') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 mt-4">
                                <div class="d-flex justify-content-center gap-4">
                                    <b-button type="submit" variant="primary" class="px-4 d-flex button-cp" :disabled="state.processing">
                                        <span v-if="state.processing" class="d-flex align-items-center">
                                            <span class="spinner-border flex-shrink-0" role="status"></span>
                                        </span>
                                        <span :class="`flex-grow-1 ${state.processing ? 'ms-2' : ''}`">{{ $t('t-update')
                                        }}</span>
                                    </b-button>
                                    <b-button @click="cancel" variant="success">{{ $t('t-cancel') }}</b-button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header";
import i18n from "@/i18n";
import { computed, onMounted, reactive, ref } from "vue";
import FlatPickr from "vue-flatpickr-component";
import useVuelidate from '@vuelidate/core';
import { helpers, required, maxLength, minValue, integer, maxValue } from '@vuelidate/validators'
import moment from "moment";
import DropZone from '@/components/widgets/dropZone2'
import { couponService } from '@/services'
import { useRoute, useRouter } from "vue-router";
import { clientOSS } from "@/helpers/axios/formDataRequest";
import Swal from "sweetalert2";
import { range } from "lodash";
import { useStore } from "vuex";
export default {
    components: {
        Layout,
        PageHeader,
        FlatPickr,
        DropZone
    },
    setup() {
        const title = i18n.global.t('coupon.management');
        const items = [
            {
                text: i18n.global.t('coupon.list'),
                href: '/admin/setting/coupon'
            },
            {
                text: i18n.global.t('coupon.editInfo'),
                active: true
            }
        ]
        const couponForm = ref(null)
        const router = useRouter();
        const { params } = useRoute()
        const oss = clientOSS;
        const dropZone = ref(null)

        const files = ref([]);
        const dropzoneFile = ref("");
        const store = useStore()
        const facilityId = computed(() => store.getters['settings/getFacilityId'])

        const initDefault = ref({
            startTime: null,
            endTime: null,
            name: '',
            code: '',
            memo: '',
            quantity: '',
            isActive: true,
            maxNumberInDay: '',
            facilityId: facilityId.value,
            image: null,
        })

        const state = reactive({
            coupon: { ...initDefault.value },
            processing: false,
            imageSrc: '',
            type: 'drop-file'
        })

        const allowedExtensions = ['image/jpeg', 'image/png', 'image/jpg']

        const coupon = computed(() => state.coupon)

        const timesMarka = range(1, 11).map(n => `${Array(n).fill('#').join('')}`)

        const toActiveBoolean = (active) => {
            return active.toString().toLowerCase() === 'true'
        }

        const rules = {
            name: {
                required: helpers.withMessage(i18n.global.t('field_required', { field: i18n.global.t('coupon.name') }), required),
                maxLength: helpers.withMessage(i18n.global.t('field_maxlength', { maxlength: 255, field: i18n.global.t('coupon.name') }), maxLength(255)),
            },
            startTime: {
                required: helpers.withMessage(i18n.global.t('field_required', { field: i18n.global.t('coupon.startTime') }), required)
            },
            endTime: {
                required: helpers.withMessage(i18n.global.t('field_required', { field: i18n.global.t('coupon.endTime') }), required)
            },
            quantity: {
                minValue: helpers.withMessage(i18n.global.t('msg.minValue', { minValue: 1, field: i18n.global.t('coupon.maxUsage') }), minValue(1)),
                maxValue: helpers.withMessage(i18n.global.t('msg.maxValue', { maxValue: 9999999999, field: i18n.global.t('coupon.maxUsage') }), maxValue(9999999999)),
                integer: helpers.withMessage(i18n.global.t('msg.onlyNumber', { field: i18n.global.t('coupon.maxUsage') }), integer)
            },
            memo: {
                maxLength: helpers.withMessage(i18n.global.t('field_maxlength', { field: i18n.global.t('coupon.remarks'), maxlength: 500 }), maxLength(500))
            }
        }

        const v$ = useVuelidate(rules, coupon)

        const startTimePickerConfig = computed(() => ({
            dateFormat: "Y-m-d H:i",
            enableTime: true,
            maxDate: coupon.value.endTime,
            minDate: null
        }))

        const endTimePickerConfig = computed(() => ({
            dateFormat: "Y-m-d H:i",
            enableTime: true,
            minDate: coupon.value.startTime
        }))

        /**
         *
         * @param {String} fieldName
         */
        const resetValidation = (fieldName) => {
            v$.value[fieldName].$reset();
        }

        const update = async () => {
            try {
                v$.value.$touch()
                if (v$.value.$invalid) return;
                let image = ''
                state.processing = true

                if (files.value.length > 0) {
                    await oss.put(dropzoneFile.value.name, dropzoneFile.value)
                    image = dropzoneFile.value.name
                }

                const coupon = {
                    ...state.coupon,
                    isActive: toActiveBoolean(state.coupon.isActive),
                    startTime: moment(state.coupon.startTime).unix(),
                    endTime: moment(state.coupon.endTime).unix(),
                    image,
                    facilityId: facilityId.value
                }
                await couponService.updateCoupon(params.couponId, coupon)
                await Swal.fire({
                    title: "",
                    text: i18n.global.t('msg.saved'),
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500,
                });
                dropzoneFile.value = ''
                files.value = []
                router.replace({ name: 'coupon_list' })
            } catch (e) {
                state.processing = false
                let { message = '' } = e.data
                if (Array.isArray(message) && message.join('').includes('facilityId')) {
                    await Swal.fire({
                        title: "",
                        text: i18n.global.t('msg.sns_facility_not_assigned'),
                        icon: "error",
                        showConfirmButton: false,
                        timer: 1500,
                    });
                }
                console.log('e', e)
            }
        }

        const cancel = () => {
            v$.value.$reset()
            state.coupon = { ...initDefault.value }
            if (initDefault.value.image) {
                dropzoneFile.value = { name: initDefault.value.image.substring(initDefault.value.image.lastIndexOf("/") + 1) }
            }
            files.value = []
            router.replace({ name: 'coupon_list' })
        }

        const drop = (e) => {
            dropzoneFile.value = e.dataTransfer.files[0];
            files.value.push(dropzoneFile.value);
        }

        const selectedFile = (file) => {
            dropzoneFile.value = file;
            files.value.push(dropzoneFile.value);
            state.imageSrc = URL.createObjectURL(dropzoneFile.value)
        }

        const viewCoupon = async () => {
            try {
                let response = await couponService.viewCoupon(params.couponId)
                if (response.image) {
                    state.type = ''
                    dropzoneFile.value = { name: response.image }
                    response.image = oss.signatureUrl(response.image)
                    state.imageSrc = response.image
                }
                initDefault.value = {
                    ...response,
                    startTime: moment.unix(response.startTime).toDate(),
                    endTime: moment.unix(response.endTime).toDate(),
                    image: response.image || ''
                }
                state.coupon = { ...initDefault.value }
                console.log(response, 'viewCoupon');
            } catch (e) {
                console.log('viewCoupon', e)
            }
        }

        const clearFile = () => {
            state.imageSrc = ''
            files.value = []
            dropzoneFile.value = ''
        }

        onMounted(() => {
            viewCoupon()
        })

        return {
            title,
            state,
            update,
            startTimePickerConfig,
            endTimePickerConfig,
            v$,
            couponForm,
            cancel,
            drop,
            selectedFile,
            dropzoneFile,
            files,
            resetValidation,
            timesMarka,
            allowedExtensions,
            clearFile,
            dropZone,
            items
        }
    },
}
</script>

<style scoped>
.white-space-preline {
    white-space: pre-line;
}

.dealInput {
    text-align: right;
    min-width: 250px;
}

.button-cp .spinner-border {
    height: 19px;
    width: 19px;
}

.usageInput {
    min-width: 250px;
}

.cp-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
}</style>
